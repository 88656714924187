import { getSmallestThumbnailUrl } from "@shopware-pwa/helpers-next";
import type { Media } from "@shopware-pwa/types";

export const getThumbnailUrl = (media: Media) => {
	const thumbnail = getSmallestThumbnailUrl(media);

	if (!thumbnail) {
		console.warn("No thumbnail found for media", media.alt);
		return media.url || "https://placehold.co/600x400";
	}

	return thumbnail;
};
